<template>
  <component
    :is="dynamicComponent"
    v-if="dynamicComponent"
    :layout-slug="layoutSlug"
    v-bind="$attrs"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutViewLoader',
  props: {
    layoutSlug: {
      type: String,
      required: true,
      default: 'default',
    },
  },
  data() {
    return {
      dynamicComponent: null,
    }
  },
  computed: {
    ...mapGetters('site', ['getThemeName']),
    customThemeLayoutLoader() {
      if (!this.layoutSlug) {
        return null
      }
      return () =>
        import(`@themes/${this.getThemeName}/views/layout-${this.layoutSlug}`)
    },
    customThemeLoader() {
      return () => import(`@themes/${this.getThemeName}/views/layout`)
    },
    defaultThemeLoader() {
      if (!this.layoutSlug) {
        return null
      }
      return () => import(`@restoBaseTheme/views/layout-${this.layoutSlug}`)
    },
  },
  mounted() {
    this.$store.dispatch('path/setPathEditability', true)
    this.customThemeLayoutLoader()
      .then(() => {
        this.dynamicComponent = () => this.customThemeLayoutLoader()
      })
      .catch(() => {
        this.customThemeLoader()
          .then(() => {
            this.dynamicComponent = () => this.customThemeLoader()
          })
          .catch(() => {
            this.defaultThemeLoader()
              .then(() => {
                this.dynamicComponent = () => this.defaultThemeLoader()
              })
              .catch(() => {
                this.dynamicComponent = () =>
                  import('@restoBaseTheme/views/layout-default')
              })
          })
      })
  },
}
</script>
